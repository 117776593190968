import moment from 'moment';
import React, { PureComponent } from 'react';
import { Link, generatePath } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { writeFile, utils } from 'xlsx';
import { Button, Tab, Tabs } from '../../../../../../components';
import { PnlStatus } from '../../../../../../components/PnlStatus';

import { IPortfolioTrade } from '../../../../../../models/IPortfolioTrade';
import { PATHS } from '../../../../../../router/paths';
import { ICurrencyFormatter } from '../../../../../../services/selectors/portfolio';
import { PortfolioHeader } from '../../../../components/PortfolioHeader';
import { SnapshotTable, TradesTable } from '../../components';
import { IRootState } from '../../../../../../services/store';
import { filterSnapshots } from '../../components/SnapshotTable/filterSnapshots';
import { filterTrades } from '../../components/TradesTable/filterTrades';
import { AxiosPromise } from 'axios';
import { IPaginationContent } from '../../../../../../models/redux/ILoadingPaginationDataState';
import CapabilitiesHelper from '../../../../../../services/utils/CapabilitiesHelper';

export enum DataType {
  TRADES,
  SNAPSHOTS,
}

const POSITIONS_INDEX = 0;

interface IOwnProps {
  portfolioInfo: IPortfolio;
  totalTrades: number;
  tradesOrder: IOrder;
  trades: IPortfolioTrade[];
  snapshots: IPortfolioSnapshot[];
  snapshotsOrder: IOrder;
  onLoadData: (type: DataType, page: number) => void;
  onSortData: (type: DataType, sort: IOrder) => void;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  onAmendTrade: (key: string) => void;
  onRemoveTrade: (tradeId: number) => void;
  assetsClasses: IAssetClass[];
  fetchData: () => void;
  snapshotFilters: IRootState['snapshot']['snapshotFilters'];
  updateSnapshotFilter(key: string, value: string | undefined): void;
  resetSnapshotFilters(): void;
  fetchPortfolioSnapshots: (settings: {
    portfolioId: string;
    sort: IOrder;
    page?: number;
    confirmed?: boolean;
    size?: number;
    fromDate?: Date;
    toDate?: Date;
  }) => AxiosPromise<IPaginationContent<IPortfolioSnapshot>>;
  snapshotsLoading?: boolean;
  userCapabilities: IRootState['userCapabilities']['data'];
  match?: { path: string };
  history?: { push(url: string): void; location: { search: string } };
}

type IProps = IOwnProps;

const getTickerFromTrade = (trade: IPortfolioTrade) => {
  // console.log(trade.instrument.assetClassId, trade.instrument.code, trade.otherCurrency, trade.otherSourceId)
  switch (trade.instrument.assetClassId) {
    // case 'Adjustments':
    //   console.log(trade)
    //   if(trade.assetSubClass?.id === "AdjFXSpot"){
    //
    //   }

    case 'Adjustments':
      console.log(trade.instrument.assetSubClassId, trade.currency, trade.otherCurrency);
      if (trade.instrument.assetSubClassId === 'CshAdjustments') {
        return trade.instrument.code;
      } else if (trade.instrument.assetSubClassId === 'AdjFXSpot') {
        return `FX ${trade.currency}${trade.otherCurrency}`;
      } else {
        return trade.instrument.code;
      }

    case 'Commodities':
      return trade.instrument.code.toUpperCase().replace('=X', '');

    case 'SubscriptionWithdrawal':
      return `subscription`;

    default:
      return trade.instrument.code;
  }
};

const getTickerFromPosition = (position: IPortfolioSnapshot) => {
  switch (position.assetClassId) {
    case 'FixedIncome':
      return position.displayId;

    case 'Funds':
      return position.displayId;

    case 'CashAndEquivalents':
      return position.code;

    case 'SubscriptionWithdrawal':
      return `subscription`;

    case 'Commodities':
      return position.displayId;

    default:
      return position.code;
  }
};

class SnapshotInfo extends PureComponent<IProps> {
  handleEditTrade = (transaction: IPortfolioTrade) => {
    const { onAmendTrade } = this.props;

    if (!transaction.key) {
      return false;
    }
    onAmendTrade(transaction.key);
  };

  handleRemoveTrade = (transaction: IPortfolioTrade) => {
    if (!transaction.key) {
      return false;
    }
    this.props.onRemoveTrade(parseInt(transaction.key, 10));
  };

  onExport = () => {
    const trades = this.props.trades
      .filter(filterTrades(this.props.snapshotFilters, this.props.portfolioInfo.currency.name))
      .map((trade) => {
        const timezoneOffset = new Date().getTimezoneOffset();
        let hours = 0;
        if (timezoneOffset > 0) {
          hours = 0;
        } else {
          hours = 12;
        }
        const date = moment(moment(trade.tradeTime).toDate().setHours(hours, 0)).format('YYYY-MM-DD hh:mm:ss');
        return {
          'Transaction Date': date,
          'Quantity/Nominal':
            trade.operation === 'SELL' || trade.operation === 'WITHDRAW' || trade.operation === 'REBATE'
              ? -trade.quantity
              : trade.quantity,
          'Ticker or ISIN (e.g. MSFT.US)': getTickerFromTrade(trade),
          'Local Price': trade.instrument.assetClassId === 'CashAndEquivalents' ? undefined : trade.price,
          'Broker/Custodian': trade.custodian.name,
          Commission: trade.commission,
          'Other Charge': trade.tradeCosts,
          'Total Transaction Amount': Math.abs(trade.netAmountBase!),
          'Settle in Local Currency':
            trade.settlementOption === 'BASE'
              ? trade.baseSettlementCurrency
              : trade.settlementOption === 'LOCAL'
              ? trade.currency
              : 'AUTO',
          // trade.baseSettlementCurrency ? '' : 'Y',
          'Stock Name': trade.stockName,
          Notes: trade.notes,
          Currency: trade.currency || '',
          'Settlement Date': trade.instrument.assetClassId === 'FixedIncome' ? trade.settlementDate || '' : undefined,
          'Accrued Interest':
            trade.instrument.assetClassId === 'FixedIncome' && !trade.accruedInterestIsCalculated
              ? trade.accruedInterestLocal || ''
              : undefined,
          'Qty 2 (FX Only)': trade.otherQuantity,
          Operation: trade.operation,
          'Instrument Source Id': trade?.instrument?.sourceId,
        };
      });

    const snapshots = this.props.snapshots
      .filter(filterSnapshots(this.props.snapshotFilters, this.props.portfolioInfo.currency.name))
      .map((snapshot: IPortfolioSnapshot) => ({
        'Asset Class': snapshot.assetClass,
        Subclass: snapshot.assetSubClass,
        Instrument: snapshot.name,
        'Ticker or ISIN (e.g. MSFT.US)': getTickerFromPosition(snapshot),
        Country: snapshot.country,
        Custodian: snapshot.custodian,
        Quantity: snapshot.quantity,
        Currency: snapshot.currencyNative,
        'Cost Price': snapshot.costPrice,
        'Last Price': snapshot.currentPriceNative,
        'Portfolio %': snapshot.percentOfPortfolio,
        'Price Move': typeof snapshot.priceGain !== 'undefined' ? snapshot.priceGain * 100 : 0,
        'Pricing Date': moment(snapshot.pricingDate).format('DD-MMM-YYYY'),
        'Total Cost': snapshot.totalCostBase,
        Notional: snapshot.notionBase,
        Exposure: snapshot.exposureBase,
        Income: snapshot.incomeHistoricBase,
        Realised: snapshot.realisedBase,
        Value: snapshot.amount,
        'P&L': snapshot.profitAndLoss,
      }));

    const worksheetTrades = utils.json_to_sheet(trades);

    const worksheetSnapshots = utils.json_to_sheet(snapshots);
    const newWorkbook = utils.book_new();

    utils.book_append_sheet(newWorkbook, worksheetTrades, 'Transactions');
    utils.book_append_sheet(newWorkbook, worksheetSnapshots, 'Positions');
    utils.book_append_sheet(newWorkbook, utils.json_to_sheet([this.props.snapshotFilters]), 'Export Filters');

    for (let i = 1; i <= trades.length; i++) {
      const tradeDateRef = utils.encode_cell({ c: 0, r: i });
      const seattlementDateRef = utils.encode_cell({ c: 12, r: i });

      newWorkbook.Sheets.Transactions[`${tradeDateRef}`].t = 'd';
      newWorkbook.Sheets.Transactions[`${seattlementDateRef}`].t = 'd';
    }

    writeFile(
      newWorkbook,
      `[${this.props.portfolioInfo.name}]_[${
        this.props.portfolioInfo.clientName
      }]_Transactions_Snapshot_${moment().format('DD-MMM-YYYY')}.xlsx`
    );
  };

  _setUrl = (tabIndex: number) => {
    const loc = tabIndex === POSITIONS_INDEX ? 'positions' : 'transactions';
    const url = generatePath(`${PATHS.portfolio.snapshot.path}${loc}`, { portfolioId: this.props.portfolioInfo?.id });
    window.history.replaceState(null, '', url);
    // this.props.history?.push(url);
  };

  render() {
    const {
      snapshots,
      trades,
      totalTrades,
      snapshotsOrder,
      tradesOrder,
      portfolioInfo,
      onSortData,
      onLoadData,
      portfolioCurrencyFormatter,
      assetsClasses,
      snapshotFilters,
      updateSnapshotFilter,
      resetSnapshotFilters,
      fetchPortfolioSnapshots,
      snapshotsLoading,
      userCapabilities,
      history,
    } = this.props;

    const iframeMode = history?.location?.search.includes('iframe-mode=true');
    const activeTabIndex = (this.props.match?.path.indexOf('transactions') ?? -1) > -1 ? 1 : 0;

    return (
      <React.Fragment>
        <div>
          <PortfolioHeader showPortfolioInfo={true} showDashboardMenu={false}>
            <ReactTooltip type="light" />
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
              <span style={{ marginRight: 15, paddingTop: 3 }}>
                <PnlStatus
                  portfolioInfo={this.props.portfolioInfo}
                  modalActive={false}
                  onGreen={this.props.fetchData}
                  showIcon={false}
                />
              </span>

              {CapabilitiesHelper.isActionAllowed(
                CapabilitiesHelper.Actions.EXPORT_TRANSACTIONS,
                this.props.userCapabilities
              ) &&
                !iframeMode && (
                  <div style={{ marginRight: 10 }}>
                    <Button variant="light" size={'small'} onClick={this.onExport}>
                      Export transactions
                    </Button>
                  </div>
                )}
              {!iframeMode && (
                <Link
                  style={{ marginRight: 10 }}
                  to={generatePath(PATHS.portfolio.snapshot.positions.add.path, { portfolioId: portfolioInfo.id })}
                >
                  <Button size={'small'} variant="light">
                    Add Positions
                  </Button>
                </Link>
              )}
              {!iframeMode &&
                CapabilitiesHelper.isActionAllowed(
                  CapabilitiesHelper.Actions.ADD_TRADE,
                  this.props.userCapabilities
                ) && (
                  <Link to={PATHS.portfolio.snapshot.edit.path.replace(':portfolioId', portfolioInfo.id)}>
                    <Button size={'small'} variant="light">
                      Add transactions
                    </Button>
                  </Link>
                )}
            </div>
          </PortfolioHeader>
        </div>
        <Tabs activeTabIndex={activeTabIndex} onTabSelected={this._setUrl}>
          <Tab label="Positions">
            <SnapshotTable
              snapshots={snapshots}
              snapshotsOrder={snapshotsOrder}
              portfolioCurrency={this.props.portfolioInfo.currency}
              onSortData={(order: any) => {
                onSortData(DataType.SNAPSHOTS, order);
              }}
              assetsClasses={assetsClasses}
              portfolioInfo={portfolioInfo}
              snapshotFilters={snapshotFilters}
              updateSnapshotFilter={updateSnapshotFilter}
              resetSnapshotFilters={resetSnapshotFilters}
              fetchPortfolioSnapshots={fetchPortfolioSnapshots}
              snapshotsLoading={snapshotsLoading}
              fetchPositionsAndTransactions={this.props.fetchData}
            />
          </Tab>
          <Tab label="Transactions">
            <TradesTable
              editable={true}
              trades={trades}
              totalTrades={totalTrades}
              tradesOrder={tradesOrder}
              portfolioCurrencyFormatter={portfolioCurrencyFormatter}
              portfolioCurrency={this.props.portfolioInfo.currency}
              onSortData={(order) => {
                onSortData(DataType.TRADES, order);
              }}
              onLoadData={(page: number) => onLoadData(DataType.TRADES, page)}
              onEditTransaction={this.handleEditTrade}
              onRemoveTransaction={this.handleRemoveTrade}
              portfolioInfo={this.props.portfolioInfo}
              assetsClasses={assetsClasses}
              snapshotFilters={snapshotFilters}
              updateSnapshotFilter={updateSnapshotFilter}
              resetSnapshotFilters={resetSnapshotFilters}
              userCapabilities={userCapabilities}
              fetchData={this.props.fetchData}
            />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}

export default SnapshotInfo;
